<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <line x1="8.66667" y1="11.9046" x2="15.3333" y2="11.9046" stroke="#0073AB" stroke-width="1.33333"
            stroke-linecap="round" />
        <line x1="11.9036" y1="15.3333" x2="11.9036" y2="8.66667" stroke="#0073AB" stroke-width="1.33333"
            stroke-linecap="round" />
    </svg>
</template>

<style lang="scss" scoped>
@if not $is-domino {
    svg {
        line {
            stroke: #000000;
        }
    }
}
</style>